@import (reference) "../_variables";
@import (reference) "../../mixins/_styleResets";
@import (reference) "../../mixins/_icons";

@topnav-height: 72px;
@logo-width: 285px;

.topnav-desktop() {
	.topnav__sections {
		display: flex;
		justify-content: flex-end;
		height: 100%;
		flex: 1;
		margin-left: @spacing-paragraph;
		max-width: (@max-width-hero - @logo-width);
	}

	.topnav__section {
		position: relative;
		height: 100%;
		display: flex;
		justify-content: center;
		padding: 0 20px 0 7px;

		.section__title {
			align-self: center;
			padding: @spacing-paragraph 0;
			font-weight: 700;
			font-size: 18px;
			font-family: 'Lato', sans-serif;

			&:focus-visible {
				outline: none;
			}

			&:hover {
				text-decoration: none;
			}

			.topnav__suitename {
				display: none;

				@media (min-width: @screen-laptop) {
					display: inline;
				}
			}
		}

		&:not(:last-child):after {
			content: "|";
			color: @color-steel;
			position: absolute;
			right: 4px;
			top: ~"calc(50% - (1em * @{line-height-factor}) / 2)";
		}

		.section__body {
			display: none;
			position: absolute;
			top: 100%;
			left: -@spacing-standard;
			max-width: 600px;
			width: 600px;

			background: @color-white;
			border: 1px solid @color-gray-8;
			border-top: none;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			box-shadow: 1px 4px 4px rgba(0, 0, 0, .25);

			li {
				padding: @spacing-paragraph/2 @spacing-standard;
				font-family: 'Lato', sans-serif;
				font-size: 16px;
				font-weight: 400;

				&:hover {
					background-color: @color-link;
					color: white;

					a {
						color: white;
					}
				}

				&:not(:last-child) {
					border-bottom: 1px solid @color-steel;
				}
			}
		}

		&:hover {
			.section__body {
				display: block;
			}
		}
	}

	.topnav__menu {
		display: none;
	}
	
	#login-button {
		font-weight: 600;
	}
	
	#sign-up-button {
		font-size: @font-size-xl;
		font-weight: @font-weight-bold;
		line-height: 20px;
		width: fit-content;
		padding: @spacing-paragraph @spacing-standard;
		color: @color-white;
		background-color: @color-blue-primary;
		box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
		min-height: revert;
		white-space: nowrap;
		
		&:hover {
			background-color: @color-brand-light;
		}
	}
}

.topnav-mobile() {
	justify-content: space-between;

	.topnav__sections {
		overflow: hidden;
		position: absolute;
		top: @topnav-height;
		background-color: white;
		left: 0;

		width: 100%;

		visibility: hidden;
		overflow-y: scroll;
		height: 0;
		transition: height .5s ease, visibility 0s linear .5s;

		&.show {
			visibility: visible;
			overflow-y: scroll;
			height: ~"calc(100vh - @{topnav-height})";
			transition: height .5s ease, visibility 0s linear 0s;
		}
	}

	.topnav__section {
		overflow: hidden;
		position: relative;
		border-bottom: 1px solid @color-steel;

		.section__title {
			font-size: 24px;
			background-color: @color-gray-e;
			padding: @spacing-standard;
			display: block;

			&:after {
				.material("expand_less");
				background-color: @color-link;
				padding: @spacing-standard;
				color: @color-white;
				position: absolute;
				top: 0;
				right: 0;
				font-size: 24px;
			}

			&.collapsed {
				background-color: white;

				&:after {
					.material("expand_more");
					background-color: @color-gray-e;
					color: @color-link;
				}
			}
		}

		.section__body {
			max-height: 0;
			transition: max-height .5s ease-out;
			background-color: @color-white;

			&.show {
				max-height: 500px;
				transition: max-height .5s ease-in;
			}

			li {
				padding: 10px 0;
				margin: 0 @spacing-standard;

				&:not(:last-child) {
					border-bottom: 1px solid @color-steel;
				}
			}
		}
	}
	
	.authentication {
		.section__title:after {
			display: none;
		}
	}
	
	#login-button {
		background-color: @color-gray-f8;
		text-align: center;
		color: @color-link;
		line-height: 34px;
		padding: 15px;
	}
	
	.sign-up {
		padding: 15px;
		background-color: @color-gray-f8;
		
		#sign-up-button {
			font-size: @font-size-xl;
			font-weight: @font-weight-bold;
			line-height: 20px;
			padding: @spacing-paragraph @spacing-standard;
			color: @color-white;
			background-color: @color-blue-primary;
			max-width: revert;
			min-height: revert;
		}
	}
}

.topnav {
	display: flex;
	color: white;
	align-items: center;
	padding: 0 @spacing-standard;
	position: relative;

	@media (min-width: @screen-max-hero) {
		padding-left: ~"calc((100vw - @{max-width-hero}) / 2)";
	}

	width: 100%;
	background-color: @color-white;
	z-index: 100;
	height: @topnav-height;

	ul {
		.plain-ul();
	}

	.topnav__sections {
		font-size: 18px;
	}

	.section__body {
		a {
			font-weight: @font-weight;
			display: block;
		}
	}

	.logo {
		font-family: 'Lora', sans-serif;
		font-weight: 700;
		font-size: 30px;
		line-height: @line-height-pct;
		color: @color-brand !important;

		display: inline-block;
		text-decoration: none !important;

		text-transform: none;

		width: @logo-width;

		&:hover {
			text-decoration: none;
		}
	}
	
	.topnav__menu {
		&:after {
			.material("close");
			display: block;
			font-size: 24px;
		}

		&.collapsed:after {
			.material("menu");
		}

		color: @color-link;
		text-decoration: none;
	}

	@media(max-width: (@screen-tablet - 1)) {
		.topnav-mobile();
	}

	@media (min-width: @screen-tablet) {
		.topnav-desktop();
	}
}

.logo {
	font-family: 'Lora', sans-serif;
	font-weight: 700;
	font-size: 30px;
	line-height: @line-height-pct;
	color: @color-brand !important;
	
	display: inline-block;
	text-decoration: none !important;
	
	text-transform: none;
	
	width: @logo-width;
	
	&:hover {
		text-decoration: none;
	}
}