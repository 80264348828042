@import (reference) "_variables";
@import (reference) "../_functions";
@import (reference) "../mixins/_styleResets";
@import (reference) "../mixins/_icons";
@import (reference) "../global/components/top-breadcrumbs";

@import "../global/global-template";

@import "components/_topNav";
@import "components/_footer.less";

@import "../global/components/_image-and-details.less";
@import "../global/components/_statsSection.less";
@import "components/_ctas.less";


body {
	scroll-behavior: smooth;
}

main {
	overflow-wrap: break-word;
	padding-bottom: 90px;
}

.hero {
	h1, p {
		color: white;
	}

	p {
		font-size: 20px;
		line-height: @line-height-pct;
	}
}

.make-breadcrumbs(@color-blue-2, @color-blue-light);

nav.breadcrumbs {
	a {
		color: @color-blue-light !important;
	}
}

h4 {
	font-family: @font-family;
	font-size: 20px;

	@media(min-width: @screen-tablet) {
		font-size: 24px;
	}

	line-height: @line-height-pct;
}

button, input[type=button], a.button {
	font-size: 24px;
	font-weight: @font-weight-header-bold;
	font-family: @font-family-header;
	text-align: center;
	vertical-align: center;
	line-height: @line-height-pct;
	text-decoration: none;
	color: white;
	display: block;

	background-color: @color-button;

	border: 2px solid @color-button;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);;

	border-radius: 4px;
	min-height: 60px;

	padding: @spacing-paragraph @spacing-standard;

	position: relative;
	cursor: pointer;

	&:active, &:hover {
		background-color: @color-button-active;
	}

	&.body-btn {
		min-width: 315px;
		display: block;
	}

	&.btn-gradient {
		background: @button-gradient;
		border: 2px solid @color-button-border;

		&:active, &:hover {
			background: @color-button-active-dark;
			color: white;
		}
	}

	&.btn-asset-box {
		font-size: 18px;
		background-color: white;
		border-radius: 2px;
		border: 1px solid @color-blue-primary;
		color: @color-blue-2;

		&:before {
			display: inline-block;
			height: 25px;
			width: 24px;
			background: url("/resources/img/79/icons/icon-bookmark.svg");
			content: "";
			vertical-align: bottom;
		}

		&:hover, &:active {
			color: white;
			background-color: @color-blue-3;

			&:before {
				background: url("/resources/img/79/icons/icon-bookmark-white.svg");
			}
		}
	}
}

.pull-quote {
	font-size: 28px;
	line-height: @line-height-pct;
}

.testimonial-text {
	font-size: 24px;
	color: @color-blue-primary;
	line-height: @line-height-pct;
}

.testimonial-name {
	font-size: 18px;
	padding-left: @spacing-standard;
	color: @color-blue-primary;
	font-weight: @font-weight-bold;
}

select {
	font-family: @font-family-header;
	background-color: @color-white;
	border-radius: 2px;
	padding: @spacing-minimum @spacing-paragraph;
	border: 1px solid @color-blue-primary;

	option {
		font-family: @font-family-header;
	}
}

.hero-action {
	> * {
		margin: auto
	}
}

ul.d-two-col {
	break-inside: avoid;
	page-break-inside: avoid;
	-webkit-column-break-inside: avoid;

	@media (min-width: @screen-tablet) {
		column-count: 2;
		column-gap: @spacing-standard;
		break-inside: avoid;
		page-break-inside: avoid;
		-webkit-column-break-inside: avoid;
	}
}

.cta-divider {
	position:relative;
	background-color: @color-steel;
	font-family: @font-family-header !important;

	padding: @spacing-standard;
	border-radius: 4px;
	display: flex;
	color: white;
	align-items: center;
	text-align: center;

	p {
		flex: 1;
		z-index: 1;
	}

	& + h2, & + h3, & + h4 {
		margin-top: 60px;
	}

	margin-top: 60px;

	flex-direction: column;

	> *:not(:first-child) {
		margin-top: @spacing-standard;

		@media (min-width: @screen-tablet) {
			margin-top: 0;
			margin-left: @spacing-standard;
		}
	}

	@media (min-width: @screen-tablet) {
		flex-direction: row;
		text-align: left;
	}
}

.shady {
	filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.25));
}

.dropdown-container {
	max-width: 100%;
}

.action-dropdown {
	max-width: 375px;

	@media (min-width: @screen-tablet) {
		min-width: 315px;
	}

	font-family: @font-family-header;

	span {
		color: white;
		grid-area: span;
		text-align: center;
		font-size: 14px;
		line-height: @line-height-pct;
	}

	a {
		color: @color-blue-2;
		font-size: 14px;
		grid-area: go;
	}

	select {
		grid-area: select;
		font-family: @font-family-header;
		max-width: 275px;
		font-size: 14px;
	}

	*:not(select) {
		font-weight: @font-weight-header-bold;
	}

	background-color: @color-blue-3;
	clip-path: url(#arrowPath);
	-webkit-clip-path: url(#arrowPath);

	display: grid;

	grid-template-columns: ~"calc(100% - 30px)" auto;
	grid-template-rows: auto auto;
	grid-template-areas:
	 "span ."
	 "select go";
	grid-gap: 5px;

	padding-left: ~"calc(max(46.5px, min(52.5px, 10% + @{spacing-paragraph})))";
	padding-top: @spacing-paragraph;
	padding-bottom: @spacing-paragraph;
	padding-right: 20px;
	align-items: center;
	justify-content: center;
}

.link-rows {
	.plain-ul();

	li {
		margin-bottom: @spacing-paragraph;
		position: relative;
		padding: @spacing-paragraph;
		padding-left: @spacing-paragraph + 20px;
		break-inside: avoid;
		border: 1px solid @color-gray-e;
		box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
		border-radius: 2px;

		&:before {
			position: absolute;
			top: @spacing-paragraph;
			left: @spacing-paragraph;
			.material-outline("\e5df");
		}
	}
}

.resource-rows {
	.plain-ul();
	border: 1px solid @color-gray-e;
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
	border-radius: 2px;
	padding: @spacing-standard @spacing-standard @spacing-paragraph;

	li {
		margin-bottom: @spacing-paragraph;

		&:before {
			.material-outline("description");
			display: inline;
			vertical-align: bottom;
		}
	}
}

.weight-n {
	font-weight: @font-weight;
}

.text-lg {
	font-size: @font-size-lg;
}

.c-black {
	color: @color-gray-3;
}

.c-white {
	color: white;
}

.c-brand {
	color: @color-brand;
}

.c-highlight {
	color: @color-highlight;
}

.margin-huge {
	margin-top: 90px !important;
}

p + ul {
	margin-top: @spacing-paragraph;
}

.body-btn {
	margin: auto;
	display: block;
}

table {
	width: 100%;
	font-family: @font-family-header;

	@media (max-width: @screen-mobile-max) {
		display: block;

		&:not(.responsive-table) {
			overflow-x: scroll;

			tbody {
				display: table;
			}
		}

		&.responsive-table {
			border: none;

			tbody {
				display: block;
				border: 1px solid @color-brand;
				border-top: 15px solid @color-brand;
			}

			tr {
				display: block;
				padding-top: @spacing-paragraph;
				border: none;
				border-top: 1px solid @color-brand;

				&:first-child {
					border: none;
					display: none;
				}
			}

			td {
				display: flex;
				column-gap: @spacing-paragraph;
				padding: 0 @spacing-paragraph @spacing-paragraph;
				align-items: center;

				border: none !important;

				&:before {
					content: attr(data-label);
					color: @color-brand;
					font-weight: 600;
					text-transform: none;
					text-align: left;
					flex: 0 0 ~"calc(50% - 15px)";
				}
			}
		}
	}

	max-width: 100%;

	tbody {
		width: 100%;
	}

	border: 1px solid @color-blue-primary;
	border-radius: 2px;
	border-collapse: collapse;

	th {
		background-color: @color-blue-2;
		color: white;
		font-weight: @font-weight-header-bold;
		text-align: left;
	}

	td, th {
		padding: @spacing-paragraph @spacing-standard;
		border: 1px solid @color-blue-primary;
	}

	margin: auto;

	&.imageplugin {
		display: table;
		width: auto;

		margin: 0;

		td {
			padding: 5px;
		}
	}
}

.steel-bleed {
	background-color: @color-steel;
}

.gray-red-bleed {
	background-color: @color-gray-red;
}

.p45 {
	padding-top: 45px;
	padding-bottom: 45px;
}

.p60 {
	padding-top: 60px !important;
	padding-bottom: 60px !important;
}

@import "../global/components/faqs";

.faqs__faq__header {
	font-family: @font-family-header;
	font-weight: 700;
}

@import "../global/components/tableOfContents";

.table-of-contents {
	margin-bottom: @spacing-standard;
}

.table-of-contents__header {
	font-family: @font-family-header;
	font-weight: 400;
	margin-bottom: @spacing-paragraph;
	font-size: 16px;
	color: @color-gray-7;
	text-transform: uppercase;
}

.float-right {
	float: right;
}
.float-left {
	float: left;
}
.stats-section__cards {
	@media(min-width: @screen-tablet){
		flex-wrap: wrap;
	}
}