@import (reference) "../_variables";
@import (reference) "../../mixins/_styleResets";

footer {
	@texas-width: 102px;
	@texas-space: @texas-width + @spacing-standard + @spacing-standard;
	@max-width-footer: ~"calc(min(@{max-width-content}, 100vw - @{texas-space}))";
	background-color: @color-gray-3;
	padding: @spacing-large @spacing-standard;
	position: relative;

	.footer-container {
		@media (min-width: @screen-tablet) {
			max-width: @max-width-footer;
		}

		@media (min-width: @screen-max-content) {
			margin-left: auto;
			margin-right: auto;
		}
	}

	ul {
		.plain-ul();
		font-family: @font-family-header;
		font-weight: @font-weight-header-bold;

		a {
			color: white !important;
		}

		li {
			margin-bottom: 10px;
		}

		@media (min-width: @screen-tablet) {
			li {
				margin-bottom: 0;
				display: inline;

				&:not(:last-child):after {
					content: " | ";
					color: @color-blue-light;
				}
			}
		}
	}

	.copyright {
		@media (min-width: @screen-tablet) {
			max-width: @max-width-footer;
		}

		@media (min-width: @screen-max-content) {
			margin-left: auto;
			margin-right: auto;
		}

		margin-top: @spacing-standard;

		@media (min-width: @screen-tablet) {
			margin-top: @spacing-paragraph;
		}

		color: white;
		font-size: 13px;
	}

	@media (min-width: @screen-tablet) {
		background-image: url("/resources/img/79/texas.svg");
		background-size: @texas-width;
		background-repeat: no-repeat;
		background-position: right @spacing-standard center;

		@media (min-width: @screen-max-content) {
			background-position: right ~"calc((100vw - @{max-width-content}) / 2)" center;
		}
	}
}